<template>
  <div class="user-view history-view">
    <div class="page-title">
      <h3>История транзакции</h3>
    </div>
    <div class="page-content">
      <div class="d-flex flex-wrap flex-md-nowrap">
        <div class="table__sh-wrapper w-50 mr-3">
          <h5 class="mb-4">Пользователи</h5>
          <table class="table__sh w-100 border-0">
            <tr class="thead">
              <td style="width: 50px">№</td>
              <td>Наименование</td>
              <td>Логин</td>
            </tr>
            <tr class="cursor-pointer"
                v-for="(user, userIndex) in usersList"
                :key="userIndex"
                @click="selectUser(user)"
                :class="activeUser(user.id) ? 'bg-grey' : ''"
            >
              <td>{{ userIndex + 1 }}</td>
              <td>{{ user.fio }}</td>
              <td>{{ user.login }}</td>
            </tr>
          </table>
          <div class="pagination-wrapper"
               v-if="userPagination.totalCount > 1"
          >
            <v-pagination
                v-model="userPagination.page"
                :classes="paginationClasses"
                :page-count="userPagination.totalCount"
            >

            </v-pagination>
          </div>
        </div>
        <div class="table__sh-wrapper w-50 mr-3" v-if="selectedUser">
          <div class="d-flex justify-content-between mb-2">
            <h5>Терминалы {{ selectedUser.fio }}</h5>
            <div class="d-flex">
              <input
                  v-model="searchTerminal"
                  type="text" class="form-control no-focus-input w-300px"
                  @keyup.enter="getUserTerminals()"
              >
              <button @click="getUserTerminals()" class="btn btn-success ml-3">
                Поиск
              </button>
            </div>
          </div>
          <table class="table__sh w-100 border-0"  v-if="userTerminals.length > 0">
            <tr class="thead position-relative">
              <td>Серия номер</td>
              <div  class="clear-select" style="top: 7px" v-if="selectedTerminal">
                <img src="../../../public/img/error.png" alt="" @click="selectedTerminal = null" >
              </div>
            </tr>
            <tr class="cursor-pointer"
                v-for="(terminal, terminalIndex) in userTerminals"
                :key="terminalIndex"
                @click="selectTerminal(terminal)"
                :class="activeTerminal(terminal.id) ? 'bg-grey' : ''"
            >
              <td>{{ terminal.serialNumber }}

              </td>
            </tr>
          </table>
          <p v-else>Нет терминалы пользователя</p>
          <div class="pagination-wrapper"
               v-if="terminalPagination.totalCount > 1"
          >
            <v-pagination
                v-model="terminalPagination.page"
                :classes="paginationClasses"
                :page-count="terminalPagination.totalCount"></v-pagination>
          </div>
        </div>
      </div>
      <div class="table__sh-wrapper mt-3 mh-440">
        <h5 v-if="selectedUser">{{ selectedUser.fio }} <span class="font-weight-normal ml-4"
                                         v-if="selectedTerminal">Терминал: {{ selectedTerminal.serialNumber }}</span>
        </h5>

        <b-tabs
            v-model="tabIndex"
            class="transaction-tabs"
            content-class="mt-3">
          <div class="d-flex justify-content-between table-header-filter align-items-center">
            <div class="datepicker-wrapper">
              <date-range-picker
                  v-model="dateRange"
                  :locale-data="ru"
                  :opens="'right'"
                  :showDropdowns="false"
                  @update="getTransactions"
                  :timePicker = "true"
                  :time-picker24-hour="true"
              >
                <template v-slot:input="picker" style="min-width: 350px;">
                  От {{ picker.startDate | moment('YYYY.MM.DD HH:mm') }} до {{ picker.endDate | moment('YYYY.MM.DD HH:mm') }}
                </template>
              </date-range-picker>

            </div>
           <div class="d-flex align-items-center">
             <div class="form-group filter-select-wrapper mb-0 position-relative">
               <multiselect
                   v-model="paginationCountMethod"
                   :options="paginationCount"
                   :multiple="false"
                   :searchable="false"
                   :show-labels="false"
                   :allow-empty="false"
                   open-direction="bottom"
               >
               </multiselect>

             </div>
             <div class="d-flex">
               <button class="excel" v-if="tabIndex != 2">
                 <download-excel
                     :fields="json_fields"
                     :fetch = "fetchData"
                     name="excelName"
                 >
                   Скачать Excel
                   <img src="../../../public/img/excell.png"  width="30px" alt="" >
                 </download-excel>
               </button>
               <div class="dropdown">
                 <button class="excel refresh-icon" v-if="isAdmin == 1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                   <img src="../../../public/img/refresh.png" alt="" width="30px">
                 </button>
                 <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                   <a class="dropdown-item text-center cursor-pointer"  @click="repeatPosPaymentTransactions">RepeatPosPaymentTransactions</a>
                   <a class="dropdown-item text-center cursor-pointer" @click="repeatPosUpdate">UpdateRepeatedTransactions</a>
                 </div>
               </div>

               
             </div>
           </div>
          </div>
          <b-tab title="Токены транзакции" active>
            <table class="table__sh w-100 border-0" >
              <tr class="thead">
                <td style="width: 50px">№</td>
                <td>Терминал</td>
                <td>Тип оплаты</td>
                <td>Дата</td>
                <td>Сумма</td>
<!--                <td style="width: 110px">ID Запроса</td>-->
                <td style="width: 100px">Платежная транзакция</td>
                <td>PaymentTokenStatus</td>
                <td>Статус</td>
                <td>Тип ощибки</td>
                <td style="width: 400px">Описание</td>
                <td>Описание подробнее </td>
              </tr>
              <tr style="background-color: #F6F8F9">
                <td></td>
                <td></td>
                <td class="p-0" style="width: 150px; overflow: inherit">
                  <div class="form-group filter-select-wrapper mb-0 position-relative">
                    <multiselect
                        v-model="paymenType"
                        :options="paymentTypes"
                        :multiple="false"
                        :searchable="false"
                        track-by="name"
                        selectLabel=" "
                        selectedLabel=" "
                        deselectLabel=" "
                        label="name"
                        placeholder="Все"
                        open-direction="bottom"
                    >
                    </multiselect>
                    <div class="clear-select" @click="clearSelect">
                      <img src="../../../public/img/error.png" alt="" >
                    </div>
                  </div>
                </td>
                <td></td>
                <td></td>
<!--                <td></td>-->
                <td>
                </td>
                <td class="p-0" style="width: 150px; overflow: inherit">
                  <div class="form-group filter-select-wrapper mb-0 position-relative">
                    <multiselect
                        v-model="paymentTokenStatus"
                        :options="paymentTokenStatuses"
                        :multiple="false"
                        :searchable="false"
                        track-by="name"
                        selectLabel=" "
                        selectedLabel=" "
                        deselectLabel=" "
                        label="name"
                        placeholder="Все"
                        open-direction="bottom"
                    >
                    </multiselect>
                    <div class="clear-select" @click="clearSelectPaymentTokenStatus">
                      <img src="../../../public/img/error.png" alt="" >
                    </div>
                  </div>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr

                  v-for="(item, itemIndex) in tokenTransactionsList"
                  :key="itemIndex"
              >
                <td>{{ itemIndex + 1 }}</td>
                <td style="text-decoration: underline; cursor: pointer" @click="terminalData(item.terminalId)">{{ item.deviceId }}</td>
                <td>{{ item.paymentTypeText }}</td>
                <td>{{ item.requestDate | commonDateFormat }}</td>
                <td>{{ item.amount / 100 | financialFormatMoney }}</td>
<!--                <td>{{ item.userRequestId }}</td>-->
                <td class="text-center px-0">
                  <b-icon v-if="item.hasPosPayment"
                          class="cursor-pointer mr-2"
                          @click="getPaymentTransactionToken(item.id)"
                          font-scale="1.2"
                          icon="check-square" variant="success"></b-icon>
                  <b-icon v-if="item.hasPosPayment"
                          class="cursor-pointer"

                          @click="posPaymentRepeat(item.id)"
                          font-scale="1.4"
                          icon="arrow-counterclockwise" variant="success"></b-icon>
                </td>
                <td>{{paymentTokenStatusName(item.paymentTokenStatus)}}</td>
                <td>{{ item.tokenStepText }} -
                  {{ item.tokenStatusText }}
                </td>
                <td>{{ item.tokenErrorTypeText }}</td>
                <td>{{ item.description }}</td>
                <td><div class="d-flex align-items-center justify-content-between">
                  <p v-if="item.states"  class="cursor-pointer" @click="transaction(item.states)"
                     style="text-decoration: underline">Подробнее...</p>
                  <div class="cursor-pointer" @click="copyToken(item.token)" v-if="isAdmin == 1">
                    <img src="../../../public/img/link.png" alt="">
                  </div>
                </div></td>
              </tr>
            </table>

            <div class="pagination-wrapper"
                 v-if="tokenTransactionPagination.totalCount > 1">
              <div class="d-flex align-items-center goto__pagination__container">
                <p>Go to: </p>
                <input type="text"
                       class="w-25 form-control no-focus-input"
                       oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*?)\..*/g, '$1');"
                       v-model="gotoTokenTransactionPage"
                       @keyup.enter="goToTokenTransactionPage" >
              </div>
              <v-pagination
                  v-model="tokenTransactionPagination.page"
                  :classes="paginationClasses"
                  :page-count="tokenTransactionPagination.totalCount"></v-pagination>

            </div>
          </b-tab>
          <b-tab title="Платежные транзакции">
            <table class="table__sh w-100 border-0">
              <tr class="thead">
                <td style="width: 60px">№</td>
                <td>Терминал</td>
                <td>Тип оплаты</td>
                <td>Дата</td>
                <td>Сумма</td>
                <td>Номер карты</td>
                <td>Статус</td>
                <td>ID поставщика</td>
                <td>Стан</td>
                <td>Мерчант ID</td>
                <td>Терминал ID</td>
                <td>approvalCode</td>
                <td>actionCode</td>
                <td>aii</td>
                <td>retrievalRefNumber</td>
                <td>localTransactionTime</td>
              </tr>
              <tr style="background-color: #F6F8F9">
                <td></td>
                <td></td>
                <td class="p-0" style="overflow: inherit">
                  <div class="form-group filter-select-wrapper mb-0 position-relative">
                    <multiselect
                        v-model="paymenType"
                        :options="paymentTypes"
                        :multiple="false"
                        :searchable="false"
                        track-by="name"
                        selectLabel=" "
                        selectedLabel=" "
                        deselectLabel=" "
                        label="name"
                        placeholder="Все"
                        open-direction="bottom"
                    >
                    </multiselect>
                    <div class="clear-select" @click="clearSelect">
                      <img src="../../../public/img/error.png" alt="" >
                    </div>
                  </div>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td class="p-0" style="overflow: inherit">
                  <div class="form-group filter-select-wrapper mb-0 position-relative">
                    <multiselect
                        v-model="status"
                        :options="paymentStatuses"
                        :multiple="false"
                        :searchable="false"
                        track-by="name"
                        selectLabel=" "
                        selectedLabel=" "
                        deselectLabel=" "
                        label="name"
                        placeholder="Все"
                        open-direction="bottom"
                    >
                    </multiselect>
                    <div class="clear-select" @click="clearSelectStatus">
                      <img src="../../../public/img/error.png" alt="" >
                    </div>
                  </div>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr
                  v-for="(item, itemIndex) in paymentTransactionsList"
                  :key="itemIndex"
              >
                <td class="pr-0" style="min-width: 50px">
                  {{ itemIndex + 1 }}
                  <b-icon
                          v-if="item.paymentStatus !== 6"
                          class="cursor-pointer"
                          @click="posPaymentRepeat(item.posEposPaymentTokenId)"
                          font-scale="1.4"
                          icon="arrow-counterclockwise" variant="success"></b-icon>

                </td>
                <td>{{ item.terminal }}</td>
                <td>{{ item.paymentTypeText }}</td>
                <td>{{ item.requestDate | commonDateFormat }}</td>
                <td>{{ item.amount / 100 | financialFormatMoney }}</td>
                <td>{{ item.pan }}</td>
                <td>
                  <p @click="getPaymentTransactionToken(item.id, true)"
                       class="cursor-pointer"
                       style="text-decoration: underline">{{ item.paymentStatusText }}</p>
                </td>
                <td>{{ item.billingTransactionId }}</td>
                <td>{{ item.stan }}</td>
                <td>{{ item.uhMerchantId }}</td>
                <td>{{ item.uhTerminalId }}</td>
                <td>{{ item.approvalCode }}</td>
                <td>{{ item.actionCode }}</td>
                <td>{{ item.aii }}</td>
                <td>{{ item.retrievalRefNumber }}</td>
                <td>{{ item.localTransactionTime }}</td>

              </tr>
            </table>
            <p v-if="paymentTransactionsList.length == 0" class="mt-3">Нет транзации</p>

            <div class="pagination-wrapper"
                 v-if="paymentTransactionPagination.totalCount > 1">
              <div class="d-flex align-items-center goto__pagination__container">
                <p>Go to: </p>
                <input type="text"
                       class="w-25 form-control no-focus-input"
                       oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*?)\..*/g, '$1');"
                       v-model="gotoPaymentTransactionPage"
                       @keyup.enter="goToPaymentsTransactionPage" >
              </div>
              <v-pagination
                  v-model="paymentTransactionPagination.page"
                  :classes="paginationClasses"
                  :page-count="paymentTransactionPagination.totalCount"></v-pagination>

            </div>
          </b-tab>
          <b-tab title="POS-события">
            <table class="table__sh w-100 border-0">
              <tr class="thead">
                <td style="width: 60px">№</td>
                <td>Терминал</td>
                <td>Дата</td>
                <td>Время событии</td>
                <td>terminalEventId</td>
                <td>type</td>
                <td>uhTerminalId</td>
                <td>batchId</td>
                <td>ipAddress</td>
                <td>code</td>
                <td>data</td>
              </tr>
              <tr
                  v-for="(item, itemIndex) in posEvents"
                  :key="itemIndex"
              >
                <td class="pr-0" style="min-width: 50px">
                  {{ itemIndex + 1 }}

                </td>
                <td>{{ item.terminal.serialNumber }}</td>
                <td>{{ item.requestDate | commonDateFormat }}</td>
                <td>{{ item.dateLong | commonDateFormat }}</td>
                <td>{{ item.terminalEventId}}</td>
                <td>{{ item.type }}</td>
                <td>{{ item.uhTerminalId }}</td>
                <td>{{ item.batchId }}</td>
                <td>{{item.ipAddress}}</td>
                <td>{{item.code}}</td>
                <td style="word-break: break-all">{{item.data}}</td>


              </tr>
            </table>
            <p v-if="posEvents.length == 0" class="mt-3">Нет транзации</p>

            <div class="pagination-wrapper"
                 v-if="posEventPagination.totalCount > 1">
              <div class="d-flex align-items-center goto__pagination__container">
                <p>Go to: </p>
                <input type="text"
                       class="w-25 form-control no-focus-input"
                       oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*?)\..*/g, '$1');"
                       v-model="gotoPosEventTransactionPage"
                       @keyup.enter="goToPosEventsTransactionPage" >
              </div>
              <v-pagination
                  v-model="posEventPagination.page"
                  :classes="paginationClasses"
                  :page-count="posEventPagination.totalCount"></v-pagination>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>
    <!--  Modals  -->
    <b-modal v-model="modalRequest"
             v-if="requestInfo"
             hide-header
             hide-footer>

      <template v-if="Object.keys(requestInfo.result).length > 0">
        <div class="row">
          <div class="col">
            <p class="font-weight-bold text-capitalize">Billing Transaction Id</p>
          </div>
          <div class="col">
            {{ requestInfo.result.billingTransactionId }}
          </div>
        </div>
        <div v-for="(item, itemIndex) in requestInfo.result.fields"
             :key="itemIndex"
             class="row">
          <div class="col">
            <p class="font-weight-bold text-capitalize">{{ item.label }}:</p>
          </div>
          <div class="col">{{ item.value }}</div>
        </div>
      </template>
      <template v-if="requestInfo.error">
        <div class="row">
          <div class="col">
            <p class="font-weight-bold">Код ощибки:</p>
          </div>
          <div class="col">{{ requestInfo.error.code }}</div>
        </div>
        <div class="row">
          <div class="col">
            <p class="font-weight-bold">Текст ощибки:</p>
          </div>
          <div class="col">{{ requestInfo.error.message }}</div>
        </div>
      </template>
    </b-modal>
    <b-modal v-model="transactionsStatesRequest"
             v-if="transactionsStates"
             hide-header
             hide-footer>

      <template>
       <div v-for="(item,index) in transactionsStates" :key="index" class="transactionsStates">
         <div class="row" >
           <div class="col d-flex justify-content-between">
             <p class="font-weight-bold text-capitalize">Step :</p>
             <p>{{item.Step}}</p>
           </div>
           <div class="col" style="font-size: 15px;word-wrap: break-word;">
           {{getTokenStepsName(item.Step)}}
           </div>
         </div>
         <div class="row" >
           <div class="col d-flex justify-content-between">
             <p class="font-weight-bold text-capitalize">Status :</p>
             <p> {{item.Status}}</p>
           </div>
           <div class="col d-flex justify-content-between" style="font-size: 15px;word-wrap: break-word;">
            {{getTokenStatusName(item.Status)}}
           </div>
         </div>
         <div class="row" >
           <div class="col d-flex justify-content-between">
             <p class="font-weight-bold text-capitalize">ErrorType :</p>
             <p>{{item.ErrorType}}</p>
           </div>
           <div class="col d-flex justify-content-between" style="font-size: 15px;word-wrap: break-word;">
             {{getTokenErrorTypesName(item.ErrorType)}}
           </div>
         </div>
         <div class="row" >
           <div class="col">
             <p class="font-weight-bold text-capitalize">TerminalDate :</p>
           </div>
           <div class="col" style="font-size: 15px;word-wrap: break-word;">
             {{ commonDateFormat(item.TerminalDate) }}
           </div>
         </div>
         <div class="row" >
           <div class="col">
             <p class="font-weight-bold text-capitalize">Description :</p>
           </div>
           <div class="col" style="font-size: 15px;word-wrap: break-word;">
             {{ item.Description }}
           </div>
         </div>
       </div>
      </template>
    </b-modal>
    <b-modal v-model="repeatPosPaymentTransactionss"
             hide-header
             hide-footer
             centered
    >

      <template v-if="repeatPosPaymentTransactionsList">
        <div class="row">
          <div class="col">
            <p class="font-weight-bold">count:</p>
          </div>
          <div class="col">{{ repeatPosPaymentTransactionsList.count}}</div>
        </div>
        <div class="row">
          <div class="col">
            <p class="font-weight-bold">errorCount:</p>
          </div>
          <div class="col">{{ repeatPosPaymentTransactionsList.errorCount}}</div>
        </div>
        <div class="row">
          <div class="col">
            <p class="font-weight-bold">successCount:</p>
          </div>
          <div class="col">{{ repeatPosPaymentTransactionsList.successCount}}</div>
        </div>
      </template>
    </b-modal>
    <b-modal v-model="isTerminalModal"
             v-if="terminalModalRequests"
             hide-header
             hide-footer>

      <template>
        <div  class="transactionsStates">
          <h4 style="text-align: center; margin-bottom: 20px">{{terminalModalRequests.serialNumber}}</h4>
          <div class="row">
            <div class="col d-flex justify-content-between">
              <p class="font-weight-bold">Uzcard MerchantId :</p>
              <p>{{terminalModalRequests.uMerchantId}}</p>
            </div>

          </div>
          <div class="row">
            <div class="col d-flex justify-content-between">
              <p class="font-weight-bold">Uzcard TerminalId :</p>
              <p>{{terminalModalRequests.uTerminalId}}</p>
            </div>

          </div>
          <div class="row">
            <div class="col d-flex justify-content-between">
              <p class="font-weight-bold"> Humo MerchantId :</p>
              <p>{{terminalModalRequests.hMerchantId}}</p>
            </div>

          </div>
          <div class="row">
            <div class="col d-flex justify-content-between">
              <p class="font-weight-bold">Humo TerminalId :</p>
              <p>{{terminalModalRequests.hTerminalId}}</p>
            </div>

          </div>

        </div>
      </template>
    </b-modal>

  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import Moment from "moment";

Date.prototype.addDays = function (days) {
  const date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};
export default {
  components: {
    Multiselect,
    DateRangePicker,

  },
  data() {
    return {
      isTerminalModal:false,
      terminalModalRequests:[],
      ru: {
        direction: 'ltr',
        format: 'yyyy.mm.dd HH:mm',
        separator: ' до ',
        applyLabel: 'Применить',
        cancelLabel: 'Отмена',
        weekLabel: 'W',
        customRangeLabel: 'Custom Range',
        daysOfWeek: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
        monthNames: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июнь', 'Июль', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
        firstDay: 1,
      },
      dateRange: {
        startDate: new Date(Moment().format('YYYY-MM-DD') + 'T00:00:00'),
        endDate: new Date(Moment().format('YYYY-MM-DD') + 'T23:55:00')
      },
      modalRequest: false,
      usersList: [],
      selectedUser: null,
      userTerminals: [],
      tokenTransactionsList: [],
      paymentTransactionsList: [],
      paymentTypes: [],
      paymentStatuses: [],
      tokenStatuses: [],
      selectedTerminal: null,
      searchTerminal: null,
      requestInfo: null,
      userPagination: {
        page: 1,
        count: 5,
        totalCount: 1
      },
      terminalPagination: {
        page: 1,
        count: 5,
        totalCount: 1
      },
      tokenTransactionPagination: {
        page: 1,
        count: 30,
        totalCount: 1,
        allCount: null
      },
      paymentTransactionPagination: {
        page: 1,
        count: 30,
        totalCount: 1,
        allCount: null
      },
      paginationClasses: {
        ul: 'pagination',
        li: 'page-item',
        liActive: 'active',
        liDisable: 'disabled',
        button: 'page-link'
      },
      noTransactions: false,
      tabIndex: 0,
      paymenType: [],
      isTable: false,
      status: null,
      transactionsStates: [],
      transactionsStatesRequest: false,
      posEvents: [],
      posEventPagination: {
        page: 1,
        count: 30,
        totalCount: 1
      },
      paginationCountMethod: 30,
      paginationCount: [10, 30, 50],
      tokenTransactionExcelList: [],
      paymentTransactionExcelList: [],
      json_fields : null,
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
      disabledBefore: new Date(2022, 1, 2),
      disabledAfter: new Date(2022, 1, 10),
      isAdmin: localStorage.getItem("role"),
      repeatPosPaymentTransactionsList:[],
      repeatPosPaymentTransactionss: false,
      gotoTokenTransactionPage: null,
      gotoPaymentTransactionPage : null,
      gotoPosEventTransactionPage : null,
      paymentTokenStatuses:[],
      paymentTokenStatus:null


    }
  },

  methods: {
    goToTokenTransactionPage(){
      if (Number(this.gotoTokenTransactionPage) > 0){
        this.tokenTransactionPagination.page = Number(this.gotoTokenTransactionPage)
      }
    },
    goToPosEventsTransactionPage(){
      if (Number(this.gotoPosEventTransactionPage) > 0){
        this.posEventPagination.page = Number(this.gotoPosEventTransactionPage)
      }
    },
    goToPaymentsTransactionPage(){
      if (Number(this.gotoPaymentTransactionPage) > 0){
        this.paymentTransactionPagination.page = Number(this.gotoPaymentTransactionPage)
      }
    },
    copyToken(text){
      const copyText = text;
      const el = document.createElement('textarea');
      el.value = copyText;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      this.successNotify('Token buferga saqlandi')
    },
    async repeatPosUpdate(){
      this.repeatPosPaymentTransactionss = true;
      try{
        this.showLoader()
        const res = await  this.$http.get('Admin/RepeatPosPaymentTransactionTokens',{
          params:{
            userId: this.selectedUser ? this.selectedUser.id : null
          }
        });
        this.repeatPosPaymentTransactionsList = res.result
      }
      catch (e) {
        this.repeatPosPaymentTransactionsList = e.response.data.result;
      } finally {
        this.hideLoader();
      }
    },
    async repeatPosPaymentTransactions(){
      this.repeatPosPaymentTransactionss = true;
      try{
        this.showLoader()
        const res = await  this.$http.get('Admin/RepeatPosPaymentTransactions',{
          params:{
            userId: this.selectedUser ? this.selectedUser.id : null
          }
        });
        this.repeatPosPaymentTransactionsList = res.result
      }
      catch (e) {
        this.repeatPosPaymentTransactionsList = e.response.data.result;
      } finally {
        this.hideLoader();
      }
    },
    getTransactions(){
      if (this.tabIndex === 0){
        this.tokenTransactionPagination.page = 1;
        this.paginationCountMethod = this.tokenTransactionPagination.count;
        this.getTokenTransactions()
      }
      else if (this.tabIndex === 1) {
        this.paymentTransactionPagination.page = 1;
        this.paginationCountMethod = this.paymentTransactionPagination.count;
        this.getPaymentTransactions()
      }
      else {
        this.posEventPagination.page = 1;
        this.paginationCountMethod = this.posEventPagination.count;
        this.getPosEvents();
      }
    },
    initializeDateRange() {
      this.dateRange.endDate = this.$moment(Date.now()).format('YYYY-MM-DD' + 'T23:59:00');
      let date = new Date(this.dateRange.endDate).getMonth();
      this.dateRange.startDate.setMonth(date, 1);
    },

    async getUsers() {
      try {
        this.showLoader();
        const res = await this.$store.dispatch('getUsers', {
          page: this.userPagination.page,
          count: this.userPagination.count
        });
        this.usersList = res.result.users;
        this.userPagination.totalCount = Math.ceil(res.result.count / this.userPagination.count);
      } catch (e) {
        this.errorNotify(e.response.data.error.message);
      } finally {
        this.hideLoader();
      }
    },

    async getPaymentTypes() {
      try {
        const res = await this.$store.dispatch('getPaymentTypes');
        this.paymentTypes = res.result.paymentTypes;
      } catch (e) {
        this.errorNotify(e.response.data.error.message);
      }
    },

    async getPaymentStatuses() {
      try {
        const res = await this.$store.dispatch('getPaymentStatuses');
        this.paymentStatuses = res.result.paymentStatuses;
      } catch (e) {
        this.errorNotify(e.response.data.error.message);
      }
    },

    async getTokenStatuses() {
      try {
        const res = await this.$store.dispatch('getTokenStatuses');
        this.tokenStatuses = res.result;

      } catch (e) {
        this.errorNotify(e.response.data.error.message);
      }
    },

    async getUserTerminals() {
      try {
        this.showLoader();
        const res = await this.$http.get('Admin/GetUserTerminals', {
          params: {
            page: this.terminalPagination.page,
            count: this.terminalPagination.count,
            userId: this.selectedUser.id,
            text: this.searchTerminal
          }
        });
        this.userTerminals = res.result.terminals;
        this.terminalPagination.totalCount = Math.ceil(res.result.count / this.terminalPagination.count);
      } catch (e) {
        this.errorNotify(e.response.data.error.message);
      } finally {
        this.hideLoader();
      }
    },

    async getTokenTransactions() {
      try {
        this.showLoader();
        const res = await this.$http.get('Admin/GetTransactions', {
          params: {
            fromDate: this.$moment(this.dateRange.startDate).format('YYYY.MM.DD HH:mm'),
            toDate: this.$moment(this.dateRange.endDate).format('YYYY.MM.DD HH:mm'),
            page: this.tokenTransactionPagination.page,
            count: this.tokenTransactionPagination.count,
            userId: this.selectedUser ? this.selectedUser.id : null,
            terminalId: this.selectedTerminal ? this.selectedTerminal.id : null,
            paymentType: this.paymenType ? this.paymenType.id : null,
            paymentTokenStatus: this.paymentTokenStatus ? this.paymentTokenStatus.id : null

          }
        })
        this.tokenTransactionsList = res.result.transactions;
        this.tokenTransactionPagination.totalCount = Math.ceil(res.result.count / this.tokenTransactionPagination.count);
        this.tokenTransactionPagination.allCount = res.result.count;
        this.noTransactions = res.result.transactions.length === 0;
      } catch (e) {
        this.errorNotify(e.response.data.error.message);
      } finally {
        this.hideLoader();
      }
    },

    async getPaymentTransactions() {
      try {
        this.showLoader();
        const res = await this.$http.get('Admin/GetPaymentTransactions', {
          params: {
            fromDate: this.$moment(this.dateRange.startDate).format('YYYY.MM.DD HH:mm'),
            toDate: this.$moment(this.dateRange.endDate).format('YYYY.MM.DD HH:mm'),
            page: this.paymentTransactionPagination.page,
            count: this.paymentTransactionPagination.count,
            userId: this.selectedUser ? this.selectedUser.id : null,
            terminalId: this.selectedTerminal ? this.selectedTerminal.id : null,
            paymentType: this.paymenType ? this.paymenType.id : null,
            paymentStatus: this.status ? this.status.id : null
          }
        })
        this.paymentTransactionsList = res.result.paymentTransactions;
        this.paymentTransactionsList.allCount = res.result.count;
        this.paymentTransactionPagination.totalCount = Math.ceil(res.result.count / this.paymentTransactionPagination.count);
      } catch (e) {
        this.errorNotify(e.response.data.error.message);
      } finally {
        this.hideLoader();
      }
    },
    selectUser(user) {
      this.selectedUser = user;
    },

    selectTerminal(terminal) {
      this.selectedTerminal = terminal;
      this.paymenType = null;
    },

    activeUser(id) {
      if (this.selectedUser) {
        return this.selectedUser.id === id
      }
    },

    activeTerminal(id) {
      if (this.selectedTerminal) {
        return this.selectedTerminal.id === id
      }
    },

    async getPaymentTransactionToken(id, paymentTransaction = false) {
      this.requestInfo = null;
      this.modalRequest = true;
      let params = {}
      params = paymentTransaction ? {id: id} : {tokenId: id};
      try {
        this.requestInfo = await this.$http.get(`Admin/GetPaymentTransaction`, {
          params: params
        });
      } catch (e) {
        this.errorNotify(e.response.data.error.message);
      }
    },

    async posPaymentRepeat(id) {
      this.showLoader()
      try {
        const res = await this.$http.patch(`Pos/PosPaymentRepeat?tokenId=${id}`);
        await this.getPaymentTransactions();
      } catch (e) {
        this.errorNotify(e.response.data.error.message);
      }
      finally {
        this.hideLoader()
      }
    },

    clearSelect(){
      if (this.paymenType.id){
        this.paymenType= null;
        this.getTokenTransactions();
        this.getPaymentTransactions()
      }
    },

    clearSelectStatus(){
        if (this.status.id){
          this.status = null
          this.getPaymentTransactions()
        }
    },
    clearSelectPaymentTokenStatus(){
      if (this.paymentTokenStatus.id>=0) {
        this.paymentTokenStatus = null
        this.getPaymentTransactions()
      }
    },

    transaction(data){
      this.transactionsStatesRequest = true;
      this.transactionsStates = JSON.parse(data)
    },
    async terminalData(id){
      try {
        const res = await this.$http.get(`Admin/GetTerminal?terminalId=${id}`);
        this.terminalModalRequests = res.result;
        this.isTerminalModal = true;

      } catch (e) {
        console.log(e)
      }


    },

    getTokenErrorTypesName(id){
      let item = this.tokenStatuses.tokenErrorTypes.find(x=> x.id == id);
      return item.name;
    },

    getTokenStatusName(id){
      let item = this.tokenStatuses.tokenStatuses.find(x=> x.id == id);
      return item.name;
    },

    getTokenStepsName(id){
      let item =  this.tokenStatuses.tokenSteps.find(x=> x.id == id);
      return item.name;
    },
   async getPosEvents(){
      try {
        this.showLoader();
        const res = await this.$http.get('Admin/GetPosEvents', {
          params: {
            fromDate: this.$moment(this.dateRange.startDate).format('YYYY.MM.DD'),
            toDate: this.$moment(this.dateRange.endDate).format('YYYY.MM.DD'),
            page: this.posEventPagination.page,
            count: this.posEventPagination.count,
            userId: this.selectedUser ? this.selectedUser.id : null,
            terminalId: this.selectedTerminal ? this.selectedTerminal.id : null,
          }
        })
        this.posEvents = res.result.posEvents;
        this.posEventPagination.totalCount = Math.ceil(res.result.count / this.posEventPagination.count);
        this.noTransactions = res.result.posEvents.length === 0;
      } catch (e) {
        this.errorNotify(e.response.data.error.message);
      } finally {
        this.hideLoader();
      }
    },
    startDownload(){
      if (this.tabIndex == 0){
        this.tokenTransactionPagination.count = this.tokenTransactionPagination.allCount
        this.getTokenTransactions()
      }
      else {
        this.paymentTransactionPagination.count = this.paymentTransactionPagination.allCount
        this.getPaymentTransactions()
      }
    },
    finishDownload(){
      this.tokenTransactionPagination.count = 30;
      this.paymentTransactionPagination = 30;

    },
     async fetchData(){
      if (this.dateRange.endDate <= this.dateRange.startDate.addDays(5)){
        if (this.tabIndex == 0){
          try {
            this.showLoader();
            const res = await this.$http.get('Admin/GetTransactions', {
              params: {
                fromDate: this.$moment(this.dateRange.startDate).format('YYYY.MM.DD'),
                toDate: this.$moment(this.dateRange.endDate).format('YYYY.MM.DD'),
                count: this.tokenTransactionPagination.allCount,
                userId: this.selectedUser ? this.selectedUser.id : null,
                terminalId: this.selectedTerminal ? this.selectedTerminal.id : null,
                paymentType: this.paymenType ? this.paymenType.id : null,
                paymentTokenStatus: this.paymentTokenStatus ? this.paymentTokenStatus.id : null
              }
            })
            return  res.result.transactions;
          } catch (e) {
            this.errorNotify(e.response.data.error.message);
          } finally {
            this.hideLoader();
            let json_fields = {
              'Id' : 'id',
              'Терминал' : 'deviceId',
              'Тип оплаты	' : 'paymentTypeText',
              'Дата' : {
                field:'requestDate',
                callback:(value) =>{
                  return `${this.$options.filters.commonDateFormat(value)}`
                }

              },
              'Сумма' : {
                field: 'amount',
                callback:(value) =>{
                  return `${this.$options.filters.financialFormatMoney(value / 100)}`
                }
              },
              'ID Запроса	': 'userRequestId',
              'Степ': 'tokenStepText',
              'Статус': 'tokenStatusText',
              'Тип ощибки	': 'tokenErrorTypeText',
              'Описание':'description'
            }
            this.json_fields = json_fields
          }


        }
        else {
          try {
            this.showLoader();
            const res = await this.$http.get('Admin/GetPaymentTransactions', {
              params: {
                fromDate: this.$moment(this.dateRange.startDate).format('YYYY.MM.DD'),
                toDate: this.$moment(this.dateRange.endDate).format('YYYY.MM.DD'),
                page: this.paymentTransactionPagination.page,
                count: this.paymentTransactionPagination.allCount,
                userId: this.selectedUser ? this.selectedUser.id : null,
                terminalId: this.selectedTerminal ? this.selectedTerminal.id : null,
                paymentType: this.paymenType ? this.paymenType.id : null,
                paymentStatus: this.status ? this.status.id : null
              }
            })
            return  res.result.paymentTransactions;
          } catch (e) {
            this.errorNotify(e.response.data.error.message);
          } finally {
            this.hideLoader();
            let json_fields = {
              'Id' : 'id',
              'Терминал' : 'terminal',
              'Тип оплаты	' : 'paymentTypeText',
              'Дата' : {
                field:'requestDate',
                callback:(value) =>{
                  return `${this.$options.filters.commonDateFormat(value)}`
                }

              },
              'Сумма' : {
                field: 'amount',
                callback:(value) =>{
                  return `${this.$options.filters.financialFormatMoney(value / 100)}`
                }
              },
              'Номер карты' : 'pan',
              'ID поставщика		': 'billingTransactionId',
              'Статус': 'paymentStatusText',
              'Мерчант ID		': {
                field: 'uhMerchantId',
                callback:(value) =>{
                  return `${value}&nbsp`
                }
              },
              'Терминал ID	':'uhTerminalId',
              'Стан': 'stan',
              'approvalCode':'approvalCode',
              'actionCode':'actionCode',
              'aii': 'aii',
              'retrievalRefNumber':{
                field: 'retrievalRefNumber',
                callback:(value) =>{
                  return `${value}&nbsp`
                }
              } ,
              'localTransactionTime': {
                field: 'localTransactionTime',
                callback:(value) =>{
                  return `${value}&nbsp;`
                }
              }




            }
            this.json_fields = json_fields
          }
        }
      }
      else{
        this.errorNotify('Выберите максимальный интервал 5 дней!!!');
      }
     },
    commonDateFormat(val){
      if (val){
        return Moment(val).isValid() ? Moment(val).format('DD.MM.YYYY HH:mm:ss.SSS') : '';
      }
    },
     async paymentTokenStatuss(){
       try {
         const res = await this.$http.get('Admin/GetPaymentTokenStatuses');
         this.paymentTokenStatuses = res.result.paymentTokenStatuses;
       } catch (e) {
         this.errorNotify(e.response.data.error.message);
       }
     },
    paymentTokenStatusName(id){
      let item = this.paymentTokenStatuses.find(x=> x.id == id);
      return item ? item.name : id;
    }

  },
  computed:{
    lowerBoundDate() {
      let date = new Date();
      if (this.dateRange.startDate) {
        let dateFrom = new Date(this.dateRange.startDate)
        date = dateFrom.addDays(5)
      }
      return date
    }

  },

  watch: {
    'status':function (val){
      this.getPaymentTransactions()
    },
    'paymenType':function (val){
      if (this.tabIndex == 0){
        this.getTokenTransactions();
      }
      else if (this.tabIndex ===1){
        this.getPaymentTransactions();
      }
      else {
        this.getPosEvents();
      }
      if (val){
        this.isTable = true
      }
    },
    'searchTerminal': function (val){
      if (val.length === 0){
        this.getUserTerminals();
      }
    },
    'selectedUser': function (val) {
      this.terminalPagination.page = 1
      this.tokenTransactionPagination.page = 1
      this.paymentTransactionPagination.page = 1
      this.posEventPagination.page = 1
      this.searchTerminal = null;
      this.gotoTokenTransactionPage = null;
      this.gotoPaymentTransactionPage = null;
      this.gotoPosEventTransactionPage = null;
      this.getUserTerminals();
      this.isTable = false;
      this.selectedTerminal = null;
      if (this.tabIndex === 0){
        this.getTokenTransactions();
      }
      else if (this.tabIndex ===1){
        this.getPaymentTransactions();
      }
      else {
        this.getPosEvents();
      }

    },
    'selectedTerminal': function (val) {
      this.tokenTransactionPagination.page = 1;
      this.paymentTransactionPagination.page = 1;
      this.posEventPagination.page = 1;
      this.gotoTokenTransactionPage = null;
      this.gotoPaymentTransactionPage = null;
      this.gotoPosEventTransactionPage = null;
      if (this.tabIndex === 0){
        this.getTokenTransactions();
      }
      else if (this.tabIndex ===1){
        this.getPaymentTransactions();
      }
      else {
        this.getPosEvents();
      }

    },
    'userPagination.page': function (val) {
      this.getUsers();
    },
    'terminalPagination.page': function (val) {
      this.getUserTerminals();
    },
    'tokenTransactionPagination.page': function (val) {
      this.getTokenTransactions();
    },
    'paymentTransactionPagination.page': function (val) {
      this.getPaymentTransactions();
    },
    'posEventPagination.page': function (val){
      this.getPosEvents();
    },
    'tabIndex': function (val) {
      if (val === 0) {
        this.paginationCountMethod = this.tokenTransactionPagination.count;
        this.getTokenTransactions();

      }
      else if (this.tabIndex ===1){
        this.paginationCountMethod = this.paymentTransactionPagination.count;
        this.getPaymentTransactions();
      }
      else {
        this.paginationCountMethod = this.posEventPagination.count;
        this.getPosEvents();
      }
    },
    'paginationCountMethod': function (val){
      if (this.tabIndex === 0){
        this.tokenTransactionPagination.count = val;
        this.getTokenTransactions();
      }
      else if (this.tabIndex ===1){
        this.paymentTransactionPagination.count = val;
        this.getPaymentTransactions();
      }
      else {
        this.posEventPagination.count = val;
        this.getPosEvents();
      }
    },
    'paymentTokenStatus':function (val){
      this.getTokenTransactions()
    }
  },

  created() {
    this.initializeDateRange();
    this.getUsers();
    this.getPaymentTypes();
    this.getPaymentStatuses();
    this.getTokenStatuses();
    this.paymentTokenStatuss()

  },

}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
.datepicker-wrapper {
  width: 350px;
  margin-bottom: 20px;
}

.vue-daterange-picker {
  width: 100%;
  height: 100%;
}
.table__sh tr td {
  padding-right: 10px;
  max-width: 400px;
}
.clear-select{
  top: 10px;
  background-color: #fff;
  padding: 0 2px;
}
.filter-select-wrapper {
  max-width: 150px !important;
  width: 150px;
}
.multiselect__content-wrapper {
  box-shadow: 1px 1px 17px 0px rgba(50, 50, 50, 0.75);
}
.transactionsStates{
  padding: 10px;
  border: 1px solid rgba(0,0,0,0.1);
  border-radius: 10px;
  margin-bottom: 10px;

}
.transactionsStates:hover{
  -webkit-box-shadow: 1px 2px 8px 7px rgba(34, 60, 80, 0.17);
  -moz-box-shadow: 1px 2px 8px 7px rgba(34, 60, 80, 0.17);
  box-shadow: 1px 2px 8px 7px rgba(34, 60, 80, 0.17);
}
.reportrange-text{
  height: 100%;
  padding: 10px 30px !important;
  box-sizing: border-box !important;
  border-radius: 40px !important;
}
.table-header-filter .filter-select-wrapper{
  width: 100px;
  margin-top: -15px;
}
.excel{
  padding: 5px 15px;
  border: 1px solid transparent;
  color: white;
  background-color: #023972;
  border-radius: 10px;
  margin-left: 10px;
  transition: .9s background-color;
  margin-top: -15px;
}
.excel:hover{
  background-color: transparent;
  color: #4e555b;
  border-color: #023972;
}
.refresh-icon{
  background-color: red;
}


</style>
